export const REPORTS_ENABLED = false;
export const SIGNING_VIEW = true;
export const SASSY_MODAL = true;
export const FEATURED_SHOPS = true;
export const SPECIAL_SHOPS = false;
export const ADD_REPLY = false;
export const SIGN_CLARITY = true;
export const AGENT_REPORTING_ENABLED = false;
export const ENABLE_ALL_PRODUCTS_ON_QUICKSHIP = true;
export const OPT_IN_ADDON_CHECKOUT_ENABLED = false;
export const SHOW_EXPECTED_SIGNING_TEXT = false;
